import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/services/blog.service';
import { Blog } from '../../models/blog';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    blogs: Blog[];

    constructor(private blogService: BlogService) { }

    ngOnInit(): void {
        this.blogService.getTop3().subscribe(
            blogs => this.blogs = blogs
        );
    }

}
