import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Page } from 'src/models/page';
import { PageService } from 'src/services/page.service';
import { Blog } from '../../models/blog';
import { SpecialPage } from '../special-page.enum';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
    url: string;
    page: Page;
    blogs: Blog[];
    speacialPages = SpecialPage;
    api = environment.apiUrl;

    constructor(private route: ActivatedRoute, private pageService: PageService, private titleService: Title, private metaService: Meta) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.url = params.get('url') ?? '/';

            this.loadPageByUrl();
        });
    }

    loadPageByUrl() {
        this.pageService.getByUrl(this.url).subscribe(
            page => {
                this.page = page;
                this.titleService.setTitle(page.title);
                this.metaService.addTags([
                    { name: 'description', content: page.description },
                    { name: 'robots', content: 'index, follow' }
                ]);
            }
        );
    }
}
