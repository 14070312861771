export class PlogImage {
    description: string;
    private_hash: string;

    /**
     *
     */
    constructor(description: string, private_hash: string) {
        this.description = description;
        this.private_hash = private_hash;
    }
}
