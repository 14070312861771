import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BlogService } from 'src/services/blog.service';
import { Blog } from '../../models/blog';

@Component({
    selector: 'app-blog-details',
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
    url: string;
    blog = null;
    api = environment.apiUrl;

    constructor(private route: ActivatedRoute, private blogService: BlogService, private titleService: Title, private metaService: Meta) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.url = params.get('url');

            this.loadBlogByUrl();

        });
    }

    loadBlogByUrl() {
        this.blogService.getByUrl(this.url)
            .subscribe(
                blog => {
                    this.blog = blog;
                    this.titleService.setTitle(blog.title);
                    this.metaService.addTags([
                        { name: 'description', content: blog.description },
                        { name: 'robots', content: 'index, follow' }
                    ]);
                }
            );
    }
}
