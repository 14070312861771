import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { PageComponent } from './page/page.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DirectusImageComponent } from './directus-image/directus-image.component';
import { CommonModule } from '@angular/common';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogItemComponent } from './blog-item/blog-item.component';
import { PlogComponent } from './plog/plog.component';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { PlogDetailsComponent } from './plog-details/plog-details.component';
@NgModule({
    declarations: [
        AppComponent,
        PageComponent,
        SafeHtmlPipe,
        DirectusImageComponent,
        BlogDetailsComponent,
        BlogItemComponent,
        PlogComponent,
        HomeComponent,
        BlogComponent,
        PlogDetailsComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
