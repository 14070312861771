import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { PageComponent } from './page/page.component';
import { PlogDetailsComponent } from './plog-details/plog-details.component';


const routes: Routes = [
    { path: 'blog/:url', component: BlogDetailsComponent },
    { path: 'plog/:url', component: PlogDetailsComponent },
    { path: ':url', component: PageComponent },
    { path: '**', component: PageComponent }
]; @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
