import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Blog } from 'src/models/blog';
import { Plog } from 'src/models/plog';
import { PlogService } from 'src/services/plog.service';

@Component({
    selector: 'app-plog-details',
    templateUrl: './plog-details.component.html',
    styleUrls: ['./plog-details.component.scss']
})
export class PlogDetailsComponent implements OnInit {
    url: string;
    api = environment.apiUrl;
    plog: Plog = null;

    constructor(private route: ActivatedRoute, private plogService: PlogService, private titleService: Title, private metaService: Meta) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.url = params.get('url');

            this.loadBlogByUrl();

        });
    }

    loadBlogByUrl() {
        this.plogService.getByUrl(this.url).subscribe(
            plog => {
                this.plog = plog;
                this.titleService.setTitle(plog.title);
                this.metaService.addTags([
                    { name: 'description', content: plog.description },
                    { name: 'robots', content: 'index, follow' }
                ]);
            }
        );
    }

}
