<div class="blog-item">
    <h1 class="title" [routerLink]="['/blog', blog.url]">{{ blog.title }}</h1>
    <div class="date"> <span>{{ blog.date | date:'fullDate' }}</span></div>
    <img [routerLink]="['/blog', blog.url]" width="100%" src="{{api}}assets/{{blog.top_image.private_hash}}?key=breed" [alt]="blog.title" />
    <div class="spoiler" [innerHtml]="blog.spoiler | safeHtml"></div>

    <div class="col-12 text-center ">
        <a class="button" [routerLink]="['/blog', blog.url]">Verder lezen</a>
    </div>
</div>
