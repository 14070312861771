import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DirectusWrapper } from 'src/models/directus-wrapper';
import { map } from 'rxjs/operators';
import { SpecialPage } from 'src/app/special-page.enum';
import { Page } from 'src/models/page';

@Injectable({
    providedIn: 'root'
})
export class PageService {

    constructor(private http: HttpClient) { }

    getMenu(): Observable<Page[]> {
        return this.http
            .get<DirectusWrapper>(`${environment.apiUrl}items/page`)
            .pipe(map(x => x.data as Page[]));
    }

    getByUrl(url: string): Observable<Page> {
        return this.http
            .get<DirectusWrapper>(`${environment.apiUrl}items/page?filter[url][eq]=${url}&single=1`)
            .pipe(map(x => x.data));
    }

    getSpecialPage(specialPage: SpecialPage): Observable<Page> {
        return this.http
            .get<DirectusWrapper>(`${environment.apiUrl}items/page?filter[system_page][eq]=${specialPage}&single=1`)
            .pipe(map(x => x.data));
    }
}
