import { Component, OnInit } from '@angular/core';
import { PlogService } from 'src/services/plog.service';
import { Plog } from '../../models/plog';

@Component({
    selector: 'app-plog',
    templateUrl: './plog.component.html',
    styleUrls: ['./plog.component.scss']
})
export class PlogComponent implements OnInit {
    plogs: Plog[];

    constructor(private plogService: PlogService) { }

    ngOnInit(): void {
        this.plogService.get().subscribe(
            plog => this.plogs = plog
        );
    }

}
