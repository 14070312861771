<div class="row">
    <div class="col-md-9">
        <ng-container *ngIf="blogs">
            <app-blog-item *ngFor="let blog of blogs" [blog]="blog"></app-blog-item>
        </ng-container>
    </div>
    <div id="over-mij">
        <h1 [routerLink]="['/over-mij']">About me</h1>
        <img [routerLink]="['/over-mij']" src="/assets/Foto MamaDoetMaar.webp" alt="">
        Ik ben Yanne, 30 jaren jong, vrouw van Mike en moeder van drie heerlijke kinderen.
        Sebas (5), Madee (2) & Rosie (0). Ik werk in het cluster 4 speciaal onderwijs en
        ben in het verleden kraamverzorgende geweest. Ik vind kinderen en hun ontwikkeling
        ontzettend interessant en heb dus veel plezier in mijn werk en het moederschap.
        Daarnaast heb ik een tikkeltje zwarte humor en een voorliefde voor wijn.
    </div>
</div>