import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Plog } from 'src/models/plog';
import { environment } from 'src/environments/environment';
import { DirectusWrapper } from 'src/models/directus-wrapper';
import { PlogImage } from 'src/models/plog-image';
import { isNullOrUndefined } from 'src/app/tools/isNullOrUndefined';

@Injectable({
    providedIn: 'root'
})
export class PlogService {


    constructor(private http: HttpClient) { }

    get(): Observable<Plog[]> {
        return this.http
            .get<DirectusWrapper>(`${environment.apiUrl}items/plog?fields=url,title,front_image.private_hash&sort=sort,-date&filter[date][lte]=now`)
            .pipe(map(x => x.data as Plog[]));
    }

    getByUrl(url: string): Observable<Plog> {
        return this.http
            .get<DirectusWrapper>(`${environment.apiUrl}items/plog?filter[url][eq]=${url}&fields=*.private_hash&single=1&sort=sort,-date&filter[date][lte]=now`)
            .pipe(map(x => {
                // make a new image array
                x.data.images = [];
                // loop through all the images/descriptions
                var numberOfImages = Object.keys(x.data).filter(x => x.startsWith('image')).length;
                for (let index = 0; index < numberOfImages; index++) {
                    if (isNullOrUndefined(x.data['image' + index])) continue;

                    // Add them when filled
                    x.data.images.push(new PlogImage(x.data['description' + index], x.data['image' + index].private_hash));
                }

                return x.data as Plog;
            }));
    }
}
