<div *ngIf="plog && plog != null" class="plog-details">
    <h1>{{ plog.title }}</h1>
    
    <div class="content" [innerHTML]="plog.description | safeHtml"></div>

    <div class="plog-image" *ngFor="let image of plog.images">
        <app-directus-image [hash]="image.private_hash" query="key=plog"></app-directus-image>
        <div class="description">
            {{ image.description }}
        </div>
    </div>
</div>
