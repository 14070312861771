import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Blog } from 'src/models/blog';
import { environment } from 'src/environments/environment';
import { DirectusWrapper } from 'src/models/directus-wrapper';

@Injectable({
    providedIn: 'root'
})
export class BlogService {
    constructor(private http: HttpClient) {
    }

    get(): Observable<Blog[]> {
        return this.http
            .get<DirectusWrapper>(`${environment.apiUrl}items/blog?fields=*,top_image.private_hash&limit=50&sort=sort,-date&filter[date][lte]=now`)
            .pipe(map(x => x.data));
    }

    getTop3(): Observable<Blog[]> {
        return this.http
            .get<DirectusWrapper>(`${environment.apiUrl}items/blog?fields=*,top_image.private_hash&limit=2&sort=sort,-date&filter[date][lte]=now`)
            .pipe(map(x => x.data));
    }

    getByUrl(url: string): Observable<Blog> {
        return this.http
            .get<DirectusWrapper>(`${environment.apiUrl}items/blog?fields=*,top_image.private_hash&sort=sort,-date&filter[url][eq]=${url}&filter[date][lte]=now`)
            .pipe(map(x => x.data[0]));
    }


}
