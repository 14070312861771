import { Component } from '@angular/core';
import { Page } from 'src/models/page';
import { PageService } from 'src/services/page.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'MamaDoetMaar.nl';
    menu: Page[];

    isShown = false;

    constructor(private pageService: PageService) {

    }
    ngOnInit(): void {
        this.pageService.getMenu().subscribe(
            menu => this.menu = menu,
            console.log
        );
    }

}
