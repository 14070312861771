import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Blog } from '../../models/blog';

@Component({
    selector: 'app-blog-item',
    templateUrl: './blog-item.component.html',
    styleUrls: ['./blog-item.component.scss']
})
export class BlogItemComponent implements OnInit {
    @Input()
    blog: Blog;

    api = environment.apiUrl;

    constructor() { }

    ngOnInit(): void {
    }

}
