import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-directus-image',
    templateUrl: './directus-image.component.html',
    styleUrls: ['./directus-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DirectusImageComponent implements OnInit {

    @Input()
    hash: string;

    @Input()
    alt: string;

    @Input()
    query = '';

    backend = environment.apiUrl;

    constructor() { }

    ngOnInit() {
    }

}
