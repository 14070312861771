import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/services/blog.service';
import { Blog } from '../../models/blog';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
    blogs: Blog[];

    constructor(private blogService: BlogService) { }

    ngOnInit(): void {
        this.blogService.get().subscribe(
            blogs => this.blogs = blogs
        );
    }

}
