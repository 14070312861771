<ng-container *ngIf="page">
    <div>
        <!-- <h1>{{ page.title }}</h1> -->
        <div class="content" [innerHTML]="page.content | safeHtml"></div>
    </div>

    <div [ngSwitch]="page.speacial_page">
        <app-home *ngSwitchCase="speacialPages.Home"></app-home>
        <app-blog *ngSwitchCase="speacialPages.Blog"></app-blog>
        <app-plog *ngSwitchCase="speacialPages.Plog"></app-plog>
    </div>
</ng-container>
